<template>

  <v-dialog
    v-model="dialogCash"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <div class="d-flex justify-space-between"> -->
      <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
      <v-btn
        color="warning"
        dark
        class="mb-2 mr-3"
        v-bind="attrs"
        v-on="on"
        @click="close"
      >
        เติมเงิน
      </v-btn>
      <!-- </div> -->
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formCash }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="!this.$route.params.id"
            >
              <v-select
                label="ลูกค้า"
                v-model="editedItem.customers_id"
                :items="customers"
                selected
                dense
                item-text="member_name"
                item-value="member_id"
              >
              </v-select>

              <small
                class="validate-err"
                v-for="(err,index) in v$.editedItem.customers_id.$silentErrors"
                v-if="v$.editedItem.customers_id.$error">{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                type="number"
                v-model="editedItem.amount_credit"
                label="จำนวนเครดิต"
              ></v-text-field>

              <small
                class="validate-err"
                v-for="(err,index) in v$.editedItem.amount_credit.$silentErrors"
                v-if="v$.editedItem.amount_credit.$error">{{ index > 0 ? ', ' : '' }}
                {{ err.$message }}</small>

            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.note"
                label="หมายเหตุ"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instance_credit_customer from '../../../services/creditCustomer'
import Swal from "sweetalert2";
import {email, minValue, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {mdiDelete, mdiPencil} from "@mdi/js";

export default {
  props: ['customer_id'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      dialogCash: false,
      editedIndex: -1,
      customers: null,
      editedItem: {
        customers_id: "",
        amount_credit: 0,
        note: "",
      },
    }
  },
  validations() {
    var valid = {
      editedItem: {
        customers_id: { },
        amount_credit: { required , minValue : (minValue(1))},
      }
    }

    if (!this.customer_id){
      valid.editedItem.customers_id = {required}
    }
    return valid
  },
  methods: {
    initCustomer() {
      this.$store.state.isLoading = true
      instance_credit_customer.listMember().then(res => {
        this.customers = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });

      this.editedItem.customers_id = this.$route.params.id
      // this.editedItem.customers_id = this.$route.params.id
    },
    close() {
      this.v$.$reset();
      this.dialogCash = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      if (!this.editedItem.customers_id || this.$route.params.id) {
        this.editedItem.customers_id = this.$route.params.id
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
      }else{
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
      }


      this.$store.state.isLoading = true

      instance_credit_customer.cashStore(this.editedItem).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        },150)
        this.close();
        this.$store.state.isLoading = false

          location.reload();
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    }
  },
  mounted() {
    this.initCustomer();
  },
  computed: {
    formCredit() {
      return this.editedIndex === -1 ? ' เติมเครดิต' : 'แก้ไข กลุ่มพนักงาน'
    },
    formCash() {
      return this.editedIndex === -1 ? ' เติมเงิน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },
}
</script>

<style scoped>
.padding__content {
  padding: 5rem 0 !important;
}

.padding__content a {
  text-decoration: none;
}
</style>
