/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getCreditCustomer(data) {
    return api.get(`credit_customers/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  all(data) {
    return api.get(`credit_customers/list?search=${data.search}&page=${data.page}&size=${data.size}`)
  },

  getCashDetail(data) {
    return api.get(`credit_customers/get_credit_customers_detail/${data.id}?type=credit&search=${data.search}`)
  },

  getCreditDetail(data) {
    return api.get(`credit_customers/get_credit_customers_detail/${data.id}?type=credit_amount&search=${data.search}`)
  },

  showEmployee(data){
    return api.get(`credit_customers/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  cashStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.post(`credit_customers/store_cash`, params, {})
  },


  creditStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`credit_customers/store_credit`, params, {})
  },


  listMember() {
    return api.get(`credit_customers/list_member`)
  },
  listTypePackage() {
    return api.get(`credit_customers/list_type_package`)
  },



}
